import './index.scss';
import {
  BASEBALL_GAME_PERIODS_OLD,
  GamePeriodsOld,
  GENERAL_GAME_PERIODS_OLD,
  HOCKEY_GAME_PERIODS_OLD
} from '../../../models/common';
import { VALID_SPORTS } from '../../../models';
import { InfoTooltip } from '..';

interface GamePeriodPickerProps {
  selectedTimePeriod: GamePeriodsOld;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<GamePeriodsOld>>;
  sport: VALID_SPORTS;
  tooltipContent?: string;
}

export const GamePeriodPickerOld = (props: GamePeriodPickerProps) => {
  const { selectedTimePeriod, setSelectedTimePeriod, sport, tooltipContent } =
    props;
  const currentGamePeriods =
    sport === 'NHL'
      ? HOCKEY_GAME_PERIODS_OLD
      : sport === 'MLB'
        ? BASEBALL_GAME_PERIODS_OLD
        : GENERAL_GAME_PERIODS_OLD;

  return (
    <div className='game-dividion-container'>
      <div className='game-dividion-selector'>
        {Object.values(currentGamePeriods).map((period) => (
          <div
            key={period}
            className={`game-dividion-tab ${selectedTimePeriod === period ? 'selected' : ''}`}
            onClick={() => {
              setSelectedTimePeriod(period);
            }}
          >
            {period}
          </div>
        ))}
      </div>
      {tooltipContent && <InfoTooltip content={tooltipContent} />}
    </div>
  );
};
