import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styles from './index.module.scss';
import { HistoricalResultsData, RESULT } from '../../../../../models/common';
import { useEffect, useState } from 'react';
import { TeamData } from '../../../../../models';
import { toDayString, toMonthDayString } from '../../../../../utils/helper';

interface TeamHistoricalTableProps {
  allTeams: TeamData[];
  historicalResultsData: HistoricalResultsData[];
  team: TeamData;
}

type TableData = {
  id: string;
  date: Date;
  day: Date;
  venue: string;
  opponent: (TeamData & { isHome: boolean }) | undefined;
  score: string;
  spread: string;
  totals: string;
};

export const TeamHistoricalTable = (props: TeamHistoricalTableProps) => {
  const { allTeams, historicalResultsData, team } = props;
  const [tableData, setTableData] = useState<TableData[]>([]);

  const getTeams = (
    { homeId, awayId }: HistoricalResultsData,
    ourTeam: TeamData
  ) => {
    const theirTeam =
      allTeams.find(
        (team) => team.int_id === (homeId === ourTeam.int_id ? awayId : homeId)
      ) || ({} as TeamData);

    return {
      ourTeam: { ...ourTeam, isHome: homeId === ourTeam.int_id },
      theirTeam: { ...theirTeam, isHome: homeId !== ourTeam.int_id }
    };
  };

  useEffect(() => {
    setTableData(
      historicalResultsData.map((historicalResults) => {
        const { ourTeam, theirTeam } = getTeams(historicalResults, team);
        const { time, betData } = historicalResults;
        const { h2h, spreads, totals } = betData;
        const isHome = ourTeam.isHome;

        return {
          id: `${time}${theirTeam.int_id}`,
          date: new Date(time),
          day: new Date(time),
          venue: isHome ? 'Home' : 'Away',
          opponent: theirTeam,
          score:
            h2h &&
            h2h.homeResult != null &&
            h2h.awayResult != null &&
            h2h.awayScore != null &&
            h2h.homeScore != null
              ? `${isHome ? h2h.homeResult[0] : h2h.awayResult[0]} ${h2h.awayScore}-${h2h.homeScore}`
              : 'No Data Found',
          spread:
            spreads &&
            spreads.homeResult != null &&
            spreads.awayResult != null &&
            spreads.spread != null
              ? `${isHome ? spreads.homeResult[0] : spreads.awayResult[0]} ${spreads.spread}`
              : 'No Data Found',
          totals:
            totals && totals.result != null && totals.total != null
              ? `${totals.result === RESULT.WIN ? 'O' : totals.result === RESULT.LOSS ? 'U' : 'P'} ${totals.total}`
              : 'No Data Found'
        };
      })
    );
  }, [historicalResultsData]);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      renderCell: ({ value }) => toMonthDayString(value)
    },
    {
      field: 'day',
      headerName: 'Day',
      renderCell: ({ value }) => toDayString(value),
      sortComparator: (v1, v2) => {
        const day1 = v1.getDay();
        const day2 = v2.getDay();

        return day1 === day2 ? 0 : day1 < day2 ? -1 : 1;
      }
    },
    {
      field: 'venue',
      headerName: 'Home/Away',
      minWidth: 145
    },
    {
      field: 'opponent',
      headerName: 'Versus',
      minWidth: 330,
      flex: 2,
      renderCell: ({ row, value }) => (
        <div>
          <img
            src={value.logo_url}
            alt={`${value.title} logo`}
            style={{ height: '52px', width: 'auto', marginRight: '5px' }}
          />
          {row.venue === 'Away' && '@ '}
          {value.title}
        </div>
      ),
      sortComparator: (v1, v2) =>
        v1.title.toLowerCase().localeCompare(v2.title.toLowerCase()),
      filterable: false
    },
    {
      field: 'score',
      headerName: 'Score',
      minWidth: 145,
      flex: 1
    },
    {
      field: 'spread',
      headerName: 'ATS',
      minWidth: 145,
      flex: 1
    },
    {
      field: 'totals',
      headerName: 'Totals',
      minWidth: 145
    }
  ];

  return (
    <div className={styles['container']}>
      <DataGrid rows={tableData} columns={columns} hideFooter />
    </div>
  );
};
