import { Fragment, PropsWithChildren, useState } from 'react';
import {
  ArrowBarLeft,
  ArrowBarRight,
  CaretDownFill
} from 'react-bootstrap-icons';

import { Navbar } from '../Navbar';
import { NavLinkIconProps } from '../NavLinkIcon';
import { SideNav } from '../SideNav/SideNav';

import './Wrapper.scss';

export interface WrapperProps {
  primaryNavOptions: {
    type: NavLinkIconProps['type'];
    path: string;
    selected?: boolean;
  }[];
  secondaryNavOptions: string[];
  selectedSecondaryNav: string;
  onSelectSecondaryNav?: (navOption: string) => void;
  sidenavContent?: {
    title: string;
    content: JSX.Element | JSX.Element[];
  }[];
}

export const Wrapper = (props: PropsWithChildren<WrapperProps>) => {
  const {
    primaryNavOptions,
    secondaryNavOptions,
    selectedSecondaryNav,
    onSelectSecondaryNav,
    sidenavContent,
    children
  } = props;

  const [expanded, setExpanded] = useState(window.innerWidth >= 1230);

  return (
    <>
      <Navbar
        secondaryNavOptions={secondaryNavOptions}
        primaryNavOptions={primaryNavOptions}
        selectedSecondaryNavOption={selectedSecondaryNav}
        handleSelectSecondarynav={onSelectSecondaryNav}
      />

      <SideNav expanded={expanded}>
        <div className='sidenav-content'>
          {sidenavContent?.map((menuItem, idx) => (
            <Fragment key={`sidenav-collapse-${idx}`}>
              <div
                role='button'
                className='sidenav-item-title'
                data-bs-toggle='collapse'
                data-bs-target={`#sidenav-collapse-${idx}`}
                aria-expanded='false'
                aria-controls={`sidenav-collapse-${idx}`}
                onClick={(elem) => {
                  // Hacky way to turn the chevron up and down depending on collapse state
                  // yeah yeah I know direct DOM manipulation in a component is bad but it's just CSS
                  const currentState =
                    elem.currentTarget.getAttribute('aria-expanded') === 'true';
                  const chevron = document.getElementById(
                    `sidenav-collapse-chevron-${idx}`
                  );
                  if (chevron && currentState) {
                    chevron.classList.add('rotate');
                    elem.currentTarget.classList.add('green');
                  } else if (chevron) {
                    chevron.classList.remove('rotate');
                    elem.currentTarget.classList.remove('green');
                  }
                }}
              >
                {menuItem.title}
                <div
                  className='collapse-chevron'
                  id={`sidenav-collapse-chevron-${idx}`}
                >
                  <CaretDownFill />
                </div>
              </div>
              <div
                className={`collapse ${menuItem.title === 'Team Selection' ? 'scroll' : ''}`}
                id={`sidenav-collapse-${idx}`}
              >
                {menuItem.content}
              </div>
            </Fragment>
          ))}
        </div>
      </SideNav>

      <div
        className='wrapper-content'
        style={{
          marginLeft: expanded ? '12rem' : 0,
          width: expanded ? 'calc(100vw - 12rem)' : '100vw',
          overflowX: 'scroll'
        }}
      >
        <div
          // In the future, add d-none here when we move sidnav content to main menu nav
          className='sidenav-toggler d-md-block d-lg-block d-xl-block'
          style={{
            left: expanded ? '11.25rem' : '-0.5rem'
          }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? (
            <ArrowBarLeft
              width={'1.5rem'}
              height={'1.75rem'}
              fontWeight={1000}
            />
          ) : (
            <ArrowBarRight
              width={'1.5rem'}
              height={'1.75rem'}
              fontWeight={1000}
            />
          )}
        </div>
        {children}
      </div>
    </>
  );
};
