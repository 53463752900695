import { IconProps } from '.';

import './Icon.scss';

export const HomeTeamWin = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/home_team_win.svg'
      alt='home team win'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay home'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);

export const HomeTeamLoss = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/home_team_loss.svg'
      alt='home team loss'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay home'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);

export const HomeGameCurrent = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/home_game_current.svg'
      alt='home game current'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay home'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);

export const AwayTeamWin = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/away_team_win.svg'
      alt='away team win'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);

export const AwayTeamLoss = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/away_team_loss.svg'
      alt='away team loss'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);

export const AwayGameCurrent = (
  props: IconProps & { number?: number; onClick?: () => void }
) => (
  <div
    onClick={props.onClick}
    style={{
      height: props.height || '1.5rem',
      width: props.width || '1.5rem',
      ...props.style
    }}
  >
    <img
      src='/icons/away_game_current.svg'
      alt='away game current'
      className='w-100 h-100'
      style={{
        objectFit: 'contain',
        height: props.height || '1.5rem',
        width: props.width || '1.5rem'
      }}
    />
    {props.number && (
      <div
        className='w-100 h-100 streak-number-overlay'
        style={
          props.height
            ? {
                fontSize: `calc(${props.height} / 2)`,
                top: `0`,
                left: `calc(-${props.height} * 0.75)`
              }
            : undefined
        }
      >
        {props.number}
      </div>
    )}
  </div>
);
