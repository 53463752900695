import styles from './index.module.scss';

export const Legend = () => {
  return (
    <div className={styles['legend-container']}>
      <div className={styles['index']}>
        <div className={styles['low']} />
        35% or less
      </div>
      <div className={styles['index']}>
        <div className={styles['high']} />
        70% or more
      </div>
    </div>
  );
};
