import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TeamResponse } from '../models/';

export const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://wager-trends-http-2-gddehje4ddbmbqhf.eastus-01.azurewebsites.net/api/v2/teams'
        : process.env.PUBLIC_URL + '/api/v2/teams',
    prepareHeaders: (headers) => {
      process.env.REACT_APP_API_KEY &&
        headers.set('x-api-key', process.env.REACT_APP_API_KEY);

      return headers;
    }
  }),
  endpoints: (builder) => ({
    // Team Queries
    getAllTeams: builder.query<
      TeamResponse,
      { league: string; sportKey: string }
    >({
      query: ({ league, sportKey }) => `get?league=${league}&sport=${sportKey}`
    }),

    getTeamById: builder.query<
      TeamResponse,
      { league: string; sportKey: string; teamId: number }
    >({
      query: ({ league, sportKey, teamId }) =>
        `get?league=${league}&sport=${sportKey}&team_id=${teamId}`
    })
  })
});

export const { useGetAllTeamsQuery, useGetTeamByIdQuery } = teamApi;
