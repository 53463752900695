import './index.scss';
import { BasicHeader, ContactUsForm } from '../../components/common';

export const Contact = () => (
  <>
    <BasicHeader />
    <div className='form-container'>
      <ContactUsForm />
    </div>
  </>
);
