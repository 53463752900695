import { useState } from 'react';
import { GamePeriodPicker } from '../../../components/common';
import { HitRateData, SUB_PAGE } from '../../../models/common';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { mapSportToApiKey } from '..';
import { TeamData, VALID_SPORTS } from '../../../models';
import { CircularProgress } from '@mui/material';
import { HitRateTable } from './components/HitRateTable';

interface HitRateProps {
  getHitRate: UseQuery<
    QueryDefinition<
      {
        league: string;
        marketKey: string;
        period?: string;
        endDate: string;
      },
      any,
      never,
      HitRateData[],
      string
    >
  >;
  selectedDate: Date;
  sport: VALID_SPORTS;
  subPage: SUB_PAGE;
  allTeams: TeamData[];
}

export const HitRate = (props: HitRateProps) => {
  const { getHitRate, selectedDate, sport, subPage, allTeams } = props;

  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');

  const {
    data: hitRateData,
    error,
    isFetching: isLoading
  } = getHitRate({
    league: mapSportToApiKey(sport).league,
    marketKey:
      subPage === SUB_PAGE.MONEYLINE
        ? 'h2h'
        : subPage === SUB_PAGE.SPREAD
          ? 'spreads'
          : subPage === SUB_PAGE.TOTAL
            ? 'totals'
            : '',
    period: selectedTimePeriod,
    endDate: new Date(selectedDate.getTime() + 86399999).toISOString()
  });

  const isSameDay = (date1: Date, date2: Date) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  return (
    <>
      <GamePeriodPicker
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        sport={sport}
      />
      <div style={{ margin: '40px 10%' }}>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <div>There has been an error. Please try again later.</div>
        ) : !hitRateData || hitRateData?.length === 0 ? (
          <div>No game data found for the selected sport and date.</div>
        ) : (
          <HitRateTable
            data={hitRateData
              .filter(({ mostRecentGame }) =>
                isSameDay(new Date(mostRecentGame), new Date(selectedDate))
              )
              .map((hitRateDatum) => {
                const team = allTeams.find(
                  ({ int_id }) => int_id === hitRateDatum.teamId
                );

                const homeAwayRecord = hitRateDatum.isHome
                  ? hitRateDatum.homeRecord
                  : hitRateDatum.awayRecord;

                return {
                  ...hitRateDatum,
                  homeAwayRecord,
                  teamName: team?.title || 'No Team Name Found'
                };
              })}
            subPage={subPage}
          />
        )}
      </div>
    </>
  );
};
