import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { store } from './store';

import { Home } from './pages/Home';
import { Provider } from 'react-redux';

import './App.scss';
import { CommonPage, DataExplorer } from './pages/common';
import { VALID_SPORTS } from './models';

import {
  useGetAllTeamsQuery,
  useGetGamesOnlyQuery,
  useGetAllGamesQuery,
  useGetRawGameDataQuery,
  useGetGamesByTeamIdQuery,
  useGetBetsByTeamIdQuery,
  useGetHistoricalResultsQuery,
  useGetHitRateQuery
} from './services';
import { ThemeProvider } from '@emotion/react';
import theme from './styles/theme';
import { Contact } from './pages/Contact';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/mlb/data-explorer',
    element: (
      <DataExplorer
        key={'mlb'}
        sport={VALID_SPORTS.MLB}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/mlb/*',
    element: (
      <CommonPage
        key={'mlb'}
        sport={VALID_SPORTS.MLB}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  },
  {
    path: '/nhl/data-explorer',
    element: (
      <DataExplorer
        key={'nhl'}
        sport={VALID_SPORTS.NHL}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nhl/*',
    element: (
      <CommonPage
        key={'nhl'}
        sport={VALID_SPORTS.NHL}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  },
  {
    path: '/nfl/data-explorer',
    element: (
      <DataExplorer
        key={'nfl'}
        sport={VALID_SPORTS.NFL}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nfl/*',
    element: (
      <CommonPage
        key={'nfl'}
        sport={VALID_SPORTS.NFL}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  },
  {
    path: '/ncaaf/data-explorer',
    element: (
      <DataExplorer
        key={'ncaaf'}
        sport={VALID_SPORTS.NCAAF}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/ncaaf/*',
    element: (
      <CommonPage
        key={'ncaaf'}
        sport={VALID_SPORTS.NCAAF}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  },
  {
    path: '/nba/data-explorer',
    element: (
      <DataExplorer
        key={'nba'}
        sport={VALID_SPORTS.NBA}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nba/*',
    element: (
      <CommonPage
        key={'nba'}
        sport={VALID_SPORTS.NBA}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  },
  {
    path: '/ncaab/data-explorer',
    element: (
      <DataExplorer
        key={'ncaab'}
        sport={VALID_SPORTS.NCAAB}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/ncaab/*',
    element: (
      <CommonPage
        key={'ncaab'}
        sport={VALID_SPORTS.NCAAB}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
        getBetsByTeamIdQuery={useGetBetsByTeamIdQuery}
        getHistoricalResultsQuery={useGetHistoricalResultsQuery}
        getHitRateQuery={useGetHitRateQuery}
      />
    )
  }
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
