import './Icon.scss';
import { IconProps } from '.';

export const BaseballIcon = (props: IconProps) => (
  <img
    src='/icons/baseball.png'
    alt='baseball'
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const HockeyIcon = (props: IconProps) => (
  <img
    src='/icons/hockey.png'
    alt='baseball'
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const BasketballIcon = (props: IconProps) => (
  <img
    src='/icons/basketball.png'
    alt='basketball'
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const FootballIcon = (props: IconProps) => (
  <img
    src='/icons/football.png'
    alt='football'
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);
