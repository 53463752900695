export * from './LineMovement.model';
export * from './MatchUp.model';
export * from './PublicData.model';
export * from './Streaks.model';
export * from './HistoricalResults.model';

export enum SECONDARY_NAV {
  STREAKS = 'streaks',
  LINE_MOVEMENT = 'line-movement',
  PUBLIC = 'public',
  MATCH_UP = 'match-up',
  HISTORICAL_RESULTS = 'old-historical-results',
  HIT_RATE = 'hit-rate',
  TEAM_HISTORICAL_RESULTS = 'historical-results'
}

export enum SUB_PAGE {
  ALL = 'All',
  MONEYLINE = 'Moneyline',
  SPREAD = 'Spread',
  TOTAL = 'Total'
}

export type GamePeriods =
  | typeof GENERAL_GAME_PERIODS
  | typeof HOCKEY_GAME_PERIODS
  | typeof BASEBALL_GAME_PERIODS;

export const GENERAL_GAME_PERIODS = {
  fullTime: { displayName: 'Full', apiCode: '' },
  firstHalf: { displayName: '1st Half', apiCode: '1h' },
  firstQuarter: { displayName: '1Q', apiCode: '1q' },
  secondQuarter: { displayName: '2Q', apiCode: '2q' },
  thirdQuarter: { displayName: '3Q', apiCode: '3q' },
  fourthQuarter: { displayName: '4Q', apiCode: '4q' }
};

export const HOCKEY_GAME_PERIODS = {
  fullTime: { displayName: 'Full', apiCode: '' },
  firstPeriod: { displayName: '1st', apiCode: '1p' },
  secondPeriod: { displayName: '2nd', apiCode: '2p' },
  thirdPeriod: { displayName: '3rd', apiCode: '3p' }
};

export const BASEBALL_GAME_PERIODS = {
  fullTime: { displayName: 'Full', apiCode: '' },
  firstInning: { displayName: '1st Inn', apiCode: '1n' },
  firstThreeInnings: { displayName: '1st-3rd Inn', apiCode: '1st3n' },
  firstHalf: { displayName: '1st Half', apiCode: '1h' }
};

export type GamePeriodsOld =
  | GENERAL_GAME_PERIODS_OLD
  | HOCKEY_GAME_PERIODS_OLD
  | BASEBALL_GAME_PERIODS_OLD;

export enum GENERAL_GAME_PERIODS_OLD {
  FULL_TIME = 'Full',
  FIRST_HALF = '1st Half',
  FIRST_QUARTER = '1Q',
  SECOND_QUARTER = '2Q',
  THIRD_QUARTER = '3Q',
  FOURTH_QUARTER = '4Q'
}

export enum HOCKEY_GAME_PERIODS_OLD {
  FULL_TIME = 'Full',
  FIRST_PERIOD = '1st',
  SECOND_PERIOD = '2nd',
  THIRD_PERIOD = '3rd'
}

export enum BASEBALL_GAME_PERIODS_OLD {
  FULL_TIME = 'Full',
  FIRST_INNING = '1st Inn',
  FIRST_THREE_INNING = '1st-3rd Inn',
  FIRST_HALF = '1st Half'
}

export enum RESULT {
  WIN = 'Win',
  LOSS = 'Loss',
  TIE = 'Tie',
  PUSH = 'Push',
  POSTPONED = 'postponed'
}

export enum VENUE {
  HOME = 'Home',
  ROAD = 'Road'
}

interface BetData {
  bookmaker: string;
  result: RESULT;
  price: {
    open: number;
    current: number;
    close?: number;
    best: number;
  };
  point: {
    open: number;
    current: number;
    close?: number;
    best: number;
  };
  percent_money: number;
  percent_bets: number;
  total_bets: number;
}

export interface TeamGameData {
  id: string;
  name: string;
  venue: VENUE;
  result: RESULT;
  score: number;
  h2h?: BetData;
  spreads?: BetData;
  totals?: BetData;
  '1hh2h'?: BetData;
  '1hspreads'?: BetData;
  '1htotals'?: BetData;
  '1qh2h'?: BetData;
  '1qspreads'?: BetData;
  '1qtotals'?: BetData;
  '2qh2h'?: BetData;
  '2qspreads'?: BetData;
  '2qtotals'?: BetData;
  '3qh2h'?: BetData;
  '3qspreads'?: BetData;
  '3qtotals'?: BetData;
  '4qh2h'?: BetData;
  '4qspreads'?: BetData;
  '4qtotals'?: BetData;
  '1ph2h'?: BetData;
  '1pspreads'?: BetData;
  '1ptotals'?: BetData;
  '2ph2h'?: BetData;
  '2pspreads'?: BetData;
  '2ptotals'?: BetData;
  '3ph2h'?: BetData;
  '3pspreads'?: BetData;
  '3ptotals'?: BetData;
  '1nh2h'?: BetData;
  '1nspreads'?: BetData;
  '1ntotals'?: BetData;
  '1st3nh2h'?: BetData;
  '1st3nspreads'?: BetData;
  '1st3ntotals'?: BetData;
}

export interface GameData {
  awayTeam: string;
  homeTeam: string;
  id: string;
  startTime: Date;
  teams: {
    [teamId: string]: TeamGameData;
  };
}

export interface BetsData {
  id: string;
  startTime: Date;
  homeTeam: string;
  awayTeam: string;
  teams: {
    away_team: TeamBets;
    home_team: TeamBets;
  };
}

interface TeamBets {
  id: string;
  venue: VENUE;
  score: number;
  q1?: number;
  q2?: number;
  q3?: number;
  q4?: number;
  p1?: number;
  p2?: number;
  p3?: number;
  ot?: number;
  result: RESULT;
  bets: {
    bet: {
      [marketKey: string]: SimpleBetData;
    };
  };
}

interface SimpleBetData {
  value: number;
  result: RESULT;
}

export interface DataExplorerBetData {
  [id: string]: {
    hash: string;
    datetime: number;
    outcome_price_home: number;
    outcome_point_home: number;
    outcome_price_away: number;
    outcome_point_away: number;
  };
}

export interface DataExplorerScoreData {
  [id: string]: {
    hash: string;
    datetime: number;
    away_team_score: number;
    home_team_score: number;
    home_team_result: 'Win' | 'Loss' | 'Tie';
    away_team_result: 'Win' | 'Loss' | 'Tie';
  };
}

export interface DataExplorerGameData {
  id: string;
  sport_key: string;
  commence_time: string;
  home_team: string;
  away_team: string;
  bet_data: {
    h2h: DataExplorerBetData;
    spreads: DataExplorerBetData;
    totals: DataExplorerBetData;
  };
  scores: DataExplorerScoreData;
}

export interface HistoricalResultsDto {
  data: HistoricalResultsData[];
  status: boolean;
}

export interface HistoricalResultsData {
  time: string;
  homeId: number;
  awayId: number;
  betData: {
    h2h: {
      homeScore?: number;
      awayScore?: number;
      homeResult?: RESULT;
      awayResult?: RESULT;
    };
    spreads: {
      spread?: number;
      homeResult?: RESULT;
      awayResult?: RESULT;
    };
    totals: {
      total?: number;
      score?: number;
      result?: RESULT;
    };
  };
}

export interface HitRateDto {
  data: HitRateData[];
  status: boolean;
}

export interface HitRateData {
  teamId: number;
  isHome: boolean;
  betType: string;
  keyAmount: number;
  mostRecentGame: string;
  totalRecord: GamesRecord;
  homeRecord: GamesRecord;
  awayRecord: GamesRecord;
  lastFiveRecord: GamesRecord;
  lastSevenRecord: GamesRecord;
  lastTenRecord: GamesRecord;
}

export interface GamesRecord {
  wins: number;
  losses: number;
  pushes: number;
}
