import './index.scss';

interface PercentTagProps {
  wins: number;
  losses: number;
  draws: number;
  disable?: boolean;
}

export const PercentTag = ({
  wins,
  losses,
  draws,
  disable = false
}: PercentTagProps) => {
  const total = wins + losses + draws;

  const percentWin = total !== 0 ? Math.round((wins / total) * 100) : 0;

  return (
    <div
      className={`percent-container ${!disable && (percentWin >= 70 ? 'high' : percentWin <= 35 ? 'low' : '')}`}
    >
      <div className='percent'>{percentWin}%</div>
      <div className='record'>{`${wins}-${losses}-${draws}`}</div>
    </div>
  );
};
