import { GridColDef } from '@mui/x-data-grid';
import './index.scss';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  GamesRecord,
  HitRateData,
  SUB_PAGE
} from '../../../../../models/common';
import { InfoTooltip, PercentTag } from '../../../../../components/common';

interface HitRateTableProps {
  data: (HitRateData & { homeAwayRecord: GamesRecord; teamName: string })[];
  subPage: SUB_PAGE;
}

export const HitRateTable = (props: HitRateTableProps) => {
  const { data, subPage } = props;

  const columns: GridColDef[] = [
    {
      field: 'teamName',
      headerName: 'Team',
      width: 190
    },
    {
      field: 'keyAmount',
      headerName: subPage,
      type: 'number',
      width: 145,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) =>
        subPage === SUB_PAGE.SPREAD && value > 0 ? `+${value}` : value
    },
    {
      field: 'isHome',
      headerName: 'Home/Away',
      width: 155,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (value ? 'Home' : 'Away')
    },
    {
      field: 'homeAwayRecord',
      headerName: 'Home/Away Record',
      renderHeader: () => (
        <>
          <div style={{ textAlign: 'center' }}>
            Home/Away
            <br />
            Record
          </div>
          <InfoTooltip content='Winning percentage below represents a team’s home or away (depending on their current location for today’s game) record for the season.' />
        </>
      ),
      width: 200,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'totalRecord',
      headerName: 'Season Record',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      width: 175,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'lastFiveRecord',
      renderHeader: () => (
        <div className='ats-header'>
          <div>ATS</div>
          <div className='flavor'>{'(Last 5)'}</div>
        </div>
      ),
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
          disable
        />
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'lastSevenRecord',
      renderHeader: () => (
        <div className='ats-header'>
          <div>ATS</div>
          <div className='flavor'>{'(Last 7)'}</div>
        </div>
      ),
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
          disable
        />
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'lastTenRecord',
      renderHeader: () => (
        <div className='ats-header'>
          <div>ATS</div>
          <div className='flavor'>{'(Last 10)'}</div>
        </div>
      ),
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
          disable
        />
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    }
  ];

  return (
    <div className='datagrid-container'>
      <DataGridPro
        getRowId={(row) => row.teamId}
        rows={data.sort(
          (d1, d2) =>
            new Date(d2.mostRecentGame).getTime() -
            new Date(d1.mostRecentGame).getTime()
        )}
        columns={columns}
        className='table-container'
        pageSizeOptions={[data.length]}
        hideFooter
      />
    </div>
  );
};
