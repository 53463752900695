import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { NavLinkIcon } from '../NavLinkIcon';

export const BasicHeader = () => (
  <div className={`${styles['home-navbar']} d-flex`}>
    <Link to='/'>
      <img
        src='./wager-trends.png'
        alt='wager-trends logo'
        className={styles['nav-logo']}
      />
    </Link>
    <div className={`${styles['home-nav']} flex-grow-1`}>
      <NavLinkIcon path='/mlb' type='mlb' />
      <NavLinkIcon path='/nhl' type='nhl' />
      <NavLinkIcon path='/nfl' type='nfl' />
      <NavLinkIcon path='/ncaaf' type='ncaaf' />
      <NavLinkIcon path='/nba' type='nba' />
      <NavLinkIcon path='/ncaab' type='ncaab' />
    </div>
    <Link style={{ alignSelf: 'center' }} to='/contact'>
      <div className={styles['contact-button']}>Contact Us</div>
    </Link>
  </div>
);
