import './index.scss';
import {
  BASEBALL_GAME_PERIODS,
  GENERAL_GAME_PERIODS,
  HOCKEY_GAME_PERIODS
} from '../../../models/common';
import { VALID_SPORTS } from '../../../models';
import { InfoTooltip } from '..';

interface GamePeriodPickerProps {
  selectedTimePeriod: string;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string>>;
  sport: VALID_SPORTS;
  tooltipContent?: string;
}

export const GamePeriodPicker = (props: GamePeriodPickerProps) => {
  const { selectedTimePeriod, setSelectedTimePeriod, sport, tooltipContent } =
    props;
  const currentGamePeriods =
    sport === 'NHL'
      ? HOCKEY_GAME_PERIODS
      : sport === 'MLB'
        ? BASEBALL_GAME_PERIODS
        : GENERAL_GAME_PERIODS;

  return (
    <div className='game-dividion-container'>
      <div className='game-dividion-selector'>
        {Object.values(currentGamePeriods).map(({ displayName, apiCode }) => (
          <div
            key={apiCode}
            className={`game-dividion-tab ${selectedTimePeriod === apiCode ? 'selected' : ''}`}
            onClick={() => {
              setSelectedTimePeriod(apiCode);
            }}
          >
            {displayName}
          </div>
        ))}
      </div>
      {tooltipContent && <InfoTooltip content={tooltipContent} />}
    </div>
  );
};
