import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BetsData,
  GameData,
  DataExplorerGameData,
  HistoricalResultsData,
  HistoricalResultsDto,
  HitRateData,
  HitRateDto
} from '../models/common';

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://wager-trends-http-2-gddehje4ddbmbqhf.eastus-01.azurewebsites.net/api'
        : process.env.PUBLIC_URL + '/api',
    prepareHeaders: (headers) => {
      process.env.REACT_APP_API_KEY &&
        headers.set('x-api-key', process.env.REACT_APP_API_KEY);

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getGamesOnly: builder.query<
      GameData[],
      { sportKey: string; league: string }
    >({
      query: ({ sportKey, league }) =>
        `games?sportKey=${sportKey}&league=${league}`
    }),

    getAllGames: builder.query<
      GameData[],
      {
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
        endDate?: string;
      }
    >({
      query: ({ marketKey, sportKey, league, includePrices, endDate }) =>
        `games?includeBetData=true&timezone=America/Chicago&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&marketKey=${marketKey}${endDate ? `&endDate=${endDate}` : ''}`,
      serializeQueryArgs: ({ queryArgs }) =>
        `${queryArgs.marketKey}-${queryArgs.sportKey}-${queryArgs.league}-${queryArgs.includePrices}${queryArgs.endDate ? `-${queryArgs.endDate}` : ''}`
    }),

    getGameById: builder.query<
      GameData,
      {
        gameId: string;
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
      }
    >({
      query: ({ gameId, marketKey, sportKey, league, includePrices }) =>
        `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&gameId=${gameId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) =>
        `${args.queryArgs.gameId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getGamesByTeamId: builder.query<
      GameData[],
      {
        teamId: string;
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
      }
    >({
      query: ({ teamId, marketKey, sportKey, league, includePrices }) =>
        `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&teamId=${teamId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) =>
        `${args.queryArgs.teamId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getRawGameData: builder.query<
      DataExplorerGameData,
      { gameId: string; marketKey: string; sportKey: string; league: string }
    >({
      query: ({ gameId, marketKey, sportKey, league }) =>
        `games?includeBetData=true&sportKey=${sportKey}&league=${league}&gameId=${gameId}&raw=true&marketKey=${marketKey}`
    }),

    getBetsByTeamId: builder.query<
      BetsData[],
      { teamId: string; marketKeys: string[]; sportKey: string; league: string }
    >({
      query: ({ teamId, marketKeys, sportKey, league }) =>
        `games/bets?includeBetData=true&teamId=${teamId}&marketKey=${marketKeys.join(',')}&sportKey=${sportKey}&league=${league}`
    }),

    getHistoricalResults: builder.query<
      HistoricalResultsData[],
      { league: string; teamId: number; period?: string }
    >({
      query: ({ league, teamId, period }) =>
        `/v2/games/historical-results?league=${league}&teamId=${teamId}&period=${period}`,
      transformResponse: (response: HistoricalResultsDto) => response.data
    }),

    getHitRate: builder.query<
      HitRateData[],
      { league: string; marketKey: string; period?: string; endDate: string }
    >({
      query: ({ league, marketKey, period, endDate }) =>
        `/v2/games/hit-rate?league=${league}&marketKey=${marketKey}&period=${period}&endDate=${endDate}`,
      transformResponse: (response: HitRateDto) => response.data
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
});

export const {
  useGetGamesOnlyQuery,
  useGetAllGamesQuery,
  useGetGameByIdQuery,
  useGetGamesByTeamIdQuery,
  useGetRawGameDataQuery,
  useGetBetsByTeamIdQuery,
  useGetHistoricalResultsQuery,
  useGetHitRateQuery
} = gameApi;
