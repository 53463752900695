import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import emailjs from '@emailjs/browser';

export const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    subject: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
    subject: false
  });

  const [status, setStatus] = useState('');

  const handleChange = ({
    target
  }:
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | SelectChangeEvent<string>) => {
    setFormData({ ...formData, [target.name]: target.value });
    setErrors({ ...errors, [target.name]: false });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors = {
      name: formData.name.trim() === '',
      email: formData.email.trim() === '',
      message: formData.message.trim() === '',
      subject: formData.subject.trim() === ''
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setStatus('Please fill in all fields.');

      return;
    }

    setStatus('Sending...');

    emailjs
      .send(
        'wagertrends', // EmailJS Service ID
        'Hekl$#_gltbvkhenila@%N', // EmailJS Template ID
        formData,
        '7muOYk8QgdA_uoEs-' // EmailJS Public Key
      )
      .then(
        () => {
          setStatus('Message sent successfully!');
          setFormData({
            name: '',
            email: '',
            message: '',
            subject: ''
          });
        },
        () => {
          setStatus('Failed to send message.');
        }
      );
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, mx: 'auto', p: 2 }}
    >
      <Typography variant='h5' gutterBottom>
        Contact Us
      </Typography>
      <TextField
        label='Name'
        name='name'
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin='normal'
        error={errors.name}
        helperText={errors.name ? 'This field is required' : ''}
      />
      <TextField
        label='Email'
        name='email'
        type='email'
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin='normal'
        error={errors.email}
        helperText={errors.email ? 'This field is required' : ''}
      />
      <FormControl fullWidth margin='normal' error={errors.subject}>
        <InputLabel>Subject</InputLabel>
        <Select name='subject' value={formData.subject} onChange={handleChange}>
          <MenuItem value='general'>General</MenuItem>
          <MenuItem value='report an issue'>Report an Issue</MenuItem>
          <MenuItem value='suggestions'>Suggestions</MenuItem>
        </Select>
        {errors.subject && (
          <Typography color='error'>This field is required</Typography>
        )}
      </FormControl>
      <TextField
        label='Message'
        name='message'
        value={formData.message}
        onChange={handleChange}
        fullWidth
        margin='normal'
        multiline
        rows={4}
        error={errors.message}
        helperText={errors.message ? 'This field is required' : ''}
      />
      <Button
        type='submit'
        variant='contained'
        fullWidth
        sx={{ mt: 2 }}
        disabled={Object.values(errors).some((error) => error)}
      >
        Send Message
      </Button>
      {status && (
        <Typography
          sx={{ mt: 2 }}
          color={status.includes('success') ? 'primary' : 'error'}
        >
          {status}
        </Typography>
      )}
    </Box>
  );
};
