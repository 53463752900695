import { IconButton, Tooltip, Zoom } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface InfoTooltipProps {
  content: string;
}

export const InfoTooltip = ({ content }: InfoTooltipProps) => (
  <Tooltip
    arrow
    slots={{
      transition: Zoom
    }}
    title={content}
  >
    <IconButton>
      <InfoOutlinedIcon color='info' />
    </IconButton>
  </Tooltip>
);
