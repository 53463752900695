import { useState } from 'react';
import { TeamData, VALID_SPORTS } from '../../../models';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { HistoricalResultsData } from '../../../models/common';
import { mapSportToApiKey } from '..';
import { GamePeriodPicker } from '../../../components/common';
import styles from './index.module.scss';
import { TeamHistoricalTable } from './components/TeamHistoricalTable';

interface TeamHistoricalResultsProps {
  allTeams: TeamData[];
  getHistoricalResults: UseQuery<
    QueryDefinition<
      {
        league: string;
        teamId: number;
        period?: string;
      },
      any,
      never,
      HistoricalResultsData[],
      string
    >
  >;
  sport: VALID_SPORTS;
}

export const TeamHistoricalResults = (props: TeamHistoricalResultsProps) => {
  const { allTeams, getHistoricalResults, sport } = props;
  const [activeTeam, setActiveTeam] = useState<TeamData | undefined>();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>('');

  const handleSelect = (event: SelectChangeEvent) => {
    setActiveTeam(
      allTeams.find(({ int_id }) => int_id === Number(event.target.value))
    );
  };

  const {
    data: historicalResultsData,
    isFetching: isLoading,
    isError
  } = getHistoricalResults({
    league: mapSportToApiKey(sport).league,
    teamId: activeTeam ? activeTeam.int_id : allTeams[0].int_id,
    period: selectedTimePeriod
  });

  return (
    <>
      <GamePeriodPicker
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        sport={sport}
      />
      <div className={styles['container']}>
        <FormControl className={styles['form']}>
          <InputLabel>Team</InputLabel>
          <Select label='Team' onChange={handleSelect}>
            {allTeams
              .slice()
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(({ int_id, title }) => (
                <MenuItem key={int_id} value={int_id}>
                  {title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {isError ? (
          <div>An error has occurred. Please try again.</div>
        ) : activeTeam ? (
          !isLoading && historicalResultsData ? (
            <TeamHistoricalTable
              allTeams={allTeams}
              historicalResultsData={historicalResultsData}
              team={activeTeam}
            />
          ) : (
            <CircularProgress />
          )
        ) : (
          <div>Please select a team.</div>
        )}
      </div>
    </>
  );
};
