import { createTheme } from '@mui/material/styles';

const rootStyles = getComputedStyle(document.documentElement);

const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 'unset',
          marginLeft: 0
        }
      }
    }
  },
  palette: {
    primary: {
      main: rootStyles.getPropertyValue('--wt-green')
    },
    secondary: {
      main: '#ff00d5'
    },
    info: {
      main: rootStyles.getPropertyValue('--gray-40')
    },
    error: {
      main: rootStyles.getPropertyValue('--wt-red')
    },
    text: {
      primary: rootStyles.getPropertyValue('--white')
    },
    mode: 'dark'
  },
  typography: {
    fontFamily: "'DM Sans', sans-serif"
  }
});

export default theme;
